/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

@import 'aesirx-uikit/dist/utils/bootstrap.scss';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';

.progress {
  height: 4px;
}

.caret-toggle {
  left: 0;
  cursor: s-resize;
  &.index {
    right: 10px;
    left: unset;
  }
  &.down {
    transform: translate(-50%, -50%) rotate(90deg) !important;
    cursor: n-resize;
  }
}

.svg_ic-menu {
  vertical-align: baseline;
}

.tui-image-editor-container .tui-image-editor-header-logo {
  display: none;
}
